//import AccountService from './AccountService';

const getHeaders = async (isAuth) => {
    const headers = { 'Content-Type': 'application/json' };
    if (isAuth) {
        headers['x-auth'] = true;
    }
    return headers;
}
const handleErrors = (response) => {
    try {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    } catch (e) {
        throw Error(response.statusText);
    }
}

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        throw Error("Can not parse");
    }
}

const fetchGet = async (url, isAuth = true) => {

    return fetch(url, { headers: await getHeaders(isAuth) }).then(handleErrors).then(parseJson);
}



export { fetchGet};