import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from './../../contexts/UserContext';

import { Row, Col, Button, Form, FormGroup } from 'reactstrap';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


const initialFormData = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
}

const DateRangeSelector = ({ loadLogs }) => {
  const { state: contextUser } = useContext(UserContext);
  const [formData, setFormData] = useState(initialFormData);

  const onChangeStartDate = (date) => {

    if (date instanceof moment) {
      setFormData({ ...formData, startDate: date.format("YYYY-MM-DD") });
    } else {
      setFormData({ ...formData, startDate: "" });
    }
  }
  const onChangeEndDate = (date) => {
    if (date instanceof moment) {
      setFormData({ ...formData, endDate: date.format("YYYY-MM-DD") });
    } else {
      setFormData({ ...formData, endDate: "" });
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const { startDate, endDate } = formData;
    if (startDate === "") {
      alert("From Date is not set")
      return;
    }
    if (endDate === "") {
      alert("To Date is not set")
      return;
    }
    loadLogs(startDate, endDate);
  }


  useEffect(() => {
    if (contextUser.fetchReady) {
      loadLogs(formData.startDate, formData.endDate);
    }

  }, [contextUser.fetchReady,formData.startDate,formData.endDate])


  return (<>

    <Form onSubmit={onFormSubmit} inline className="">
      <Row className="w-100">
        <Col className="my-auto">
          <FormGroup className="shadow px-3 py-2 mr-3">
            <i className="text-primary mr-2" aria-hidden="true"></i>
            <ReactDatetime className="text-dark dark" timeFormat={false} inputProps={{ className: "pb-0 w-100 text-dark border-0 rounded-0", placeholder: "From Date" }}
              value={moment(formData.startDate)}
              closeOnSelect={true} onChange={onChangeStartDate}
              isValidDate={(currentDate) => {
                if (formData.endDate) {
                  return currentDate.isSameOrBefore(moment(formData.endDate));
                }
                return true;
              }}
            />
          </FormGroup>
        </Col>
        <Col className="my-auto">
          <FormGroup className="shadow px-3 py-2 mr-3">
            <i className="text-primary mr-2" aria-hidden="true"></i>
            <ReactDatetime className="text-dark dark" timeFormat={false} inputProps={{ className: "pb-0 w-100 text-dark border-0 rounded-0", placeholder: "To Date" }}
              value={moment(formData.endDate)}
              closeOnSelect={true} onChange={onChangeEndDate}
              isValidDate={(currentDate) => {
                if (formData.startDate) {
                  return currentDate.isSameOrAfter(moment(formData.startDate))
                }
                return true;
              }} />
          </FormGroup>
        </Col>
        <Col className="my-auto">
          <Button className="py-2 rounded-0 search-btn btn-block" type="submit" color="dark">Set Date</Button>
        </Col>
      </Row>
    </Form>


  </>
  );
}
export default DateRangeSelector;