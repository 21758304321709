export default {
    SUPERVISOR_MAP: {
        "94703390801": "Lahiru sam",
        "94705956283": "Jinadasa",
        "94705956284": "Shantha",
        "94710149425": "Shalinda",
        "94710435278": "Thanveer",
        "94710747221": "Isuru",
        "94710949948": "Pramod",
        "94710971798": "Suranga",
        "94711467307": "Chamara",
        "94712203665": "Pawan",
        "94712502407": "Dinuka",
        "94712795161": "Herath",
        "94714065935": "Hasanka",
        "94714107023": "Edirisinghe",
        "94714178711": "Vithana",
        "94714178715": "Pradeep",
        "94714178725": "Rohan",
        "94714178729": "Hasitha",
        "94714178730": "Panagoda",
        "94714178731": "Samantha",
        "94714178732": "Kumarage",
        "94714178733": "Kandambi",
        "94714186374": "Indika",
        "94714307675": "Tony",
        "94714348523": "Jagath",
        "94714528266": "Lakmal Ed",
        "94715356969": "Nalin",
        "94715362190": "Dudly",
        "94715362192": "Jagath - Site",
        "94715858812": "Samitha",
        "94715858825": "Thilina",
        "94715858842": "Saliya",
        "94715858876": "Harini",
        "94715858893": "Amila",
        "94716823659": "Nimadha",
        "94716823660": "Chanaka",
        "94718623397": "Anushka",
        "94718623399": "Haroshika",
        "94718623400": "Lasantha",
        "94718623402": "Isuru",
        "94718648773": "Senan",
        "94718648774": "Lalith",
        "94718648775": "Eranda",
        "94718698858": "Upul",
        "94719101125": "Preethi",
        "94772469281": "Jayasooriya",
        "94705956293": "Edirisooriya",
        "94705956290": "Abeyrathna",
        "94705956295": "Gunathilaka",
        "94703809740": "Adikari",
        "94705956292": "Pushpakumara",
        "94715858704": "Udari"
    }
}