import React, { createContext, useReducer, useCallback } from "react";

const UserContextActions = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    FETCH_READY: false
};
const UserContext = createContext();


let initialState = {
    isAuth: false,
    username: "",
    token: null,
    exp: null,
    fetchReady: false
};

let reducer = (state, action) => {
    switch (action.type) {
        case UserContextActions.LOGOUT: {

            return { ...initialState };

        }
        case UserContextActions.LOGIN: {

            return { ...state, isAuth: true, username: action.username, token: action.token, exp: action.exp };
        }

        case UserContextActions.FETCH_READY: {
            return { ...state, fetchReady: action.status }
        }
        default:
            return state;
    }
};

const UserContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const customDispatch = useCallback(async (action) => {
        switch (action.type) {
            case UserContextActions.LOGOUT: {
                dispatch({ type: UserContextActions.LOGOUT });
                localStorage.removeItem("token");
                window.location.replace(`https://getshoutout.com/logout?referrer=${window.location.origin}`);
                break;
            }

            default: {
                dispatch(action);
            }
        }
    },[]);

    const value = { state, dispatch: customDispatch };

    return (
        <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
    );
}

const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer, UserContextActions };