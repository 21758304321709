import React, { useState } from 'react';
import { Row, Col, Card, CardHeader, CardTitle } from 'reactstrap';
import LogsTable from './LogsTable';
import DateRangeSelector from './DateRangeSelector';
import DownloadButton from './DownloadButton';
import { getIncomingMessageLog } from './../../services/Services';

import Constants from './../../Constants';
import { notify } from 'react-notify-toast';
import moment from 'moment';

const Logs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadLogs = async (fromDate, toDate) => {
    try {
      setIsLoading(true);
      const logsResponse = await getIncomingMessageLog(fromDate, toDate);

      const transformedResponse = logsResponse.reduce((result, { _id, sourceId, message, createdOn }) => {

        try {
          const entries = message.split('#');
          entries.forEach(entry => {
            const splitted = entry.trim().split(',');
            if (splitted.length > 2) {
              const [jobNo, employeeId, time] = splitted;
              result.push({
                createdDate: moment(createdOn).format("YYYY-MM-DD"),
                createdTime: moment(createdOn).format("HH:mm"),
                supervisorName: Constants.SUPERVISOR_MAP[sourceId],
                supervisorMobile: sourceId,
                jobNumber: jobNo.trim(),
                employeeNumber: employeeId.trim(),
                time: time.trim()
              })
            }
          });


        } catch (e) {

        }
        return result;
      }, []);

      setData(transformedResponse);

      setIsLoading(false);
    } catch (e) {
      notify.show("Couldn't load the data.", "error");
    }

  };



  return (<>
    <div className="container-fluid">
      <Row className="h100 justify-content-center">
        <Col md={10} sm={12} className="mt-3">
          <Card className="border-0 shadow mt-5">
            <CardHeader className="bg-transparent border-0">
              <CardTitle className="text-center mt-3">
                <h2>Illuk Auto | Employee Logs</h2>
              </CardTitle>
            </CardHeader>
            <Row>
              <Col className="ml-4 pr-0">
                <DateRangeSelector loadLogs={loadLogs} />
              </Col>
              <Col className="pl-0 mr-4" md={3}>
                <DownloadButton data={data} />
              </Col>
            </Row>
            <LogsTable data={data} isLoading={isLoading} />
          </Card>
        </Col>
      </Row>
    </div>
  </>
  );
}
export default Logs;