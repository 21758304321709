import React from 'react';
import { CardBody } from 'reactstrap';
import ReactTable from 'react-table';

const Logs = ({ data, isLoading }) => {
  return (<>
    <CardBody>
      {/* <CSVLink data={data} className="btn btn-dark rounded-0 mb-3 py-2 menu-position" >Download Logs</CSVLink> */}
      <ReactTable
        data={data}
        loading={isLoading}
        loadingText="Loading Logs..."
        noDataText="No Logs Found"
        filterable
        defaultFilterMethod={(filter, row) =>
          (row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())}
        columns={[
          {
            Header: "SMS Date",
            accessor: "createdDate"
          },
          {
            Header: "SMS Time",
            accessor: "createdTime"
          },
          {
            Header: "Supervisor Name",
            accessor: "supervisorName",
            Cell: props =>
              <span>{props.original.supervisorName}<small className="my-auto mx-2 text-muted">({props.original.supervisorMobile})</small></span>
          },
          {
            Header: "Job Number",
            accessor: "jobNumber",
          },
          {
            Header: "Employee Number",
            accessor: "employeeNumber"
          },
          {
            Header: "Time",
            accessor: "time"
          },

        ]}
        defaultPageSize={10}
        className="-highlight -borderless"
      />
    </CardBody>
  </>
  );
}
export default React.memo(Logs);