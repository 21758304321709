import React, { useState, useContext } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { UserContext, UserContextActions } from './../../contexts/UserContext';

const NavigationBar = () => {
  const { state: contextUser, dispatch: dispatchUser } = useContext(UserContext);
  const [isOpen, toggle] = useState(false);

  const logout = async () => {
    dispatchUser({ type: UserContextActions.LOGOUT });
  }
  return (
    <div>
      <img src="./logo.png" className="shadow-sm img-fluid logo-menu-position" alt="logo"/>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Settings
                </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  {contextUser.username}
                  </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logout}>
                  Logout
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );

}

export default React.memo(NavigationBar);