import React, { useEffect, useContext } from 'react';
import Logs from './../Logs/Logs';
import Navigationbar from './../Navigation/NavigationBar.js';
import { UserContext, UserContextActions } from './../../contexts/UserContext';
import { UtilServices } from './../../services/Services';
const constantMock = window.fetch;
const Home = () => {
    const { state: contextUser, dispatch: dispatchUser } = useContext(UserContext);

    useEffect(() => {


        if (contextUser.isAuth) {

            window.fetch = async function () {
                // Get the parameter in arguments
                // Intercept the parameter here 
                const body = arguments[1];

                if (body.headers['x-auth']) {
                    delete body.headers['x-auth'];
                    if (UtilServices.isTokenTimeNotExpired(contextUser.exp)) {
                        body.headers['Authorization'] = "Bearer " + contextUser.token;
                    } else {
                        dispatchUser({ type: UserContextActions.LOGOUT });

                        return;
                    }
                }

                const response = await constantMock.apply(this, arguments);

                if (response.status === 401 || response.status === 403) {//token expired
                    dispatchUser({ type: UserContextActions.LOGOUT });

                }

                return response;
            }
            dispatchUser({ type: UserContextActions.FETCH_READY, status: true });

        } else {
            window.fetch = constantMock;
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("token")) {
                const token = urlParams.get("token");
                localStorage.setItem("token", token);

                const { so_user_name, exp } = UtilServices.decodeToken(token);

                dispatchUser({ type: UserContextActions.LOGIN, token, exp, username: so_user_name });
                window.history.replaceState({}, document.title, "/");
            } else if (localStorage.getItem("token")) {
                const token = localStorage.getItem("token");
                const { so_user_name, exp } = UtilServices.decodeToken(token);

                dispatchUser({ type: UserContextActions.LOGIN, token, exp, username: so_user_name });
            } else {
                dispatchUser({ type: UserContextActions.LOGOUT });
            }

        }




        return () => { window.fetch = constantMock }
    }, [contextUser.isAuth]); // The empty array causes this effect to only run on mount


    return (
        <>
            <Navigationbar />
            
            <Logs />
        </>
    );
}
export default Home;