import jwtDecode from 'jwt-decode';
import moment from 'moment';

class UtilServices {

    static isTokenTimeNotExpired(exp) {
        if (exp) {
            return moment.unix(exp - 10, 'x').isAfter(moment());//check 10 seconds ahead
        }

        return false;
    }
    static decodeToken(token) {
        return jwtDecode(token);

    }

}


export default UtilServices;