import React from 'react';
import { CSVLink } from 'react-csv';
import {Button} from 'reactstrap';

const DownloadButton = ({ data }) => {

  return (<>
    {data.length > 0 ?
      <CSVLink data={data} filename={"export_logs.csv"} className="btn btn-primary rounded-0 mb-3 py-2 btn-block" >Download Logs</CSVLink> :
      <Button color="primary" className="rounded-0 mb-3 py-2" block disabled>Download Logs</Button>
    }
  </>
  );
}
export default DownloadButton;