import React from 'react';

import Home from './components/Home/Home';
import { UserContextProvider } from './contexts/UserContext';
import Notifications from 'react-notify-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-table/react-table.css'
import './App.css';

const App = () => {
  return (
    <UserContextProvider>
      <Notifications />
      <Home />
    </UserContextProvider>
  );
}

export default App;
